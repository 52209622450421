import React from 'react';
import {
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="October 2022"
      subnav="release-notes">
      <div id="October2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          The Uniform team spent October scaring off some creepy crawlers
          (fixing bugs) and working on some behind-the-scenes architectural
          changes that will make collaboration and staying up-to-date easier
          than ever. The web components source code is now part of the{' '}
          <Link href="https://github.com/hudl/hudl-frontends">
            frontends monorepo
          </Link>
          , alongside other Hudl front-end packages and apps.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Fixed>Incorrect colors in the Do/Don't images.</Fixed>
              <Fixed>
                Incorrect color values on the{' '}
                <Link href="/guidelines/colors/design">
                  color guidelines pages
                </Link>
                .
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.35.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Fixed>
                Incorrect background color for v3{' '}
                <Link href="http://uniform.hudltools.com/components/action-bar/design">
                  Action Bar
                </Link>
                .
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.24.2"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <Paragraph className="uni-margin--one--top">
              Uniform v4 web source code has moved to the{' '}
              <Link href="https://github.com/hudl/hudl-frontends">
                frontends monorepo
              </Link>
              !
            </Paragraph>
            <div className={styles.changesList}>
              <Fixed>
                Issue with CI build process that incorrectly set the "default"
                npm package version.
              </Fixed>
            </div>
          </div>
          {/* <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.2.1"
              versionUrl="https://github.com/hudl/rn-uniform/releases"
            />
            <div className={styles.changesList}>
              <Fixed>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Fixed>
            </div>
          </div> */}
          {/* <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />
            <div className={styles.changesList}>
              <Fixed>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Fixed>
            </div>
          </div> */}
          {/* <div className={styles.platform}>
            <PlatformHeader libName="Storybook" />
            <div className={styles.changesList}>
              <Removed>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Removed>
            </div>
          </div> */}
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
